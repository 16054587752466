import { render, staticRenderFns } from "./FormTextarea.vue?vue&type=template&id=314946d3&scoped=true"
import script from "./FormTextarea.vue?vue&type=script&lang=ts"
export * from "./FormTextarea.vue?vue&type=script&lang=ts"
import style0 from "./FormTextarea.vue?vue&type=style&index=0&id=314946d3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314946d3",
  null
  
)

export default component.exports