import Popover from '@/components/Popover.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'CouponModalInputHeader',
    components: { Popover },
    props: {
        popoverContent: {
            type: String,
            default: ''
        },
        title: {
            type: [String, Object],
            default: ''
        },
        isTitleHtml: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        }
    }
});
